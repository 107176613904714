import { User } from '@/utils/eventTypes';
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
type UserAvatarProps = {
    user: User;
    className: string;
};

const UserAvatar = ({ user, className }: UserAvatarProps) => {
    // if no user show person icon
    if (!user) {
        return (
            <div className="w-8 h-8 border rounded-full bg-violet-50 rounded-full flex items-center justify-center">
                <PersonIcon />
            </div>
        );
    }

    let avatar = '';
    // use photo if available
    if ('photo' in user) {
        avatar = user.photo;
    }

    const { avatar: userAvatar, name } = user;

    // use avatar if available
    if (avatar === '') {
        avatar = userAvatar;
    }

    const getInitials = () => {
        if (!name) {
            return <PersonIcon />;
        }

        const initials = name
            .split(' ')
            .map((word) => word.charAt(0))
            .join('');

        return initials;
    };

    return (
        <div className="user-avatar">
            {avatar ? (
                <img
                    src={avatar}
                    alt={name}
                    className={className}
                    referrerPolicy="no-referrer"
                />
            ) : (
                // fallback to initials
                <div className="w-8 h-8 border rounded-full bg-violet-50 rounded-full flex items-center justify-center">
                    {getInitials()}
                </div>
            )}
        </div>
    );
};

export default UserAvatar;
