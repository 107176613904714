import React from 'react';

export const BondaLogo = ({
    className = 'md:w-[160px] md:h-[62px] w-[120px] h-[46px]',
}: {
    className?: string;
}) => {
    return (
        <img
            className={`${className}`}
            src="/bonda-beta.png"
            alt="Bonda Logo"
        />
    );
};
