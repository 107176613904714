import { useAuth } from '../../utils/auth';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { useGetCompany } from '../../hooks';
import Link from 'next/link';
import UserAvatar from '../common/UserAvatar';
import { SubscriptionStatus } from '@/utils/apiTypes';

const SubscribeLink = ({ status }: { status: SubscriptionStatus }) => {
    const subscriptionText = {
        active: 'Subscription is active',
        canceled: 'Re-activate your subscription',
        trialing: `You're on a free trial`,
        past_due: 'Your subscription is past due',
        unpaid: 'Your subscription is unpaid',
        incomplete: 'Your subscription is incomplete',
        incomplete_expired: 'Your subscription is incomplete',
        noStatus: 'Start your 30-day free trial',
    };

    return (
        <Menu.Button
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
            <Link href="/subscription" passHref>
                <span className="px-3 py-0.5 bg-[#51D390] text-[12px] text-black rounded-md mr-2">
                    {subscriptionText[status] || subscriptionText.noStatus}
                </span>
            </Link>
        </Menu.Button>
    );
};

export const Header = ({ user }) => {
    const { signOut } = useAuth();
    const { company } = useGetCompany();
    return (
        <div className="px-4 flex justify-end">
            <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                    <div className="flex justify-center">
                        {company && (
                            <SubscribeLink
                                status={company?.subscription.status}
                            />
                        )}
                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span className="sr-only">Open user menu</span>
                            <UserAvatar
                                user={user?.userDetail}
                                className="h-8 w-8 rounded-full"
                            />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => {
                                            signOut();
                                        }}
                                        className={`${
                                            active ? 'bg-grey-100' : ''
                                        }  block px-4 py-2 text-sm text-gray-700`}
                                    >
                                        Logout
                                    </button>
                                )}
                            </Menu.Item>
                            {/* {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? "bg-grey-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))} */}
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </div>
    );
};
