import { CompanyType } from '@/utils/apiTypes';
import { useAuth } from '@/utils/auth';
import { authFetch } from '@/utils/authFetch';
import { useEffect, useState } from 'react';

// TODO: implement global context for data
export const useGetCompany = () => {
    const { authToken, companyId } = useAuth();
    const [company, setCompany] = useState<CompanyType | null>(null);
    useEffect(() => {
        if (companyId) {
            authFetch(authToken, `/web/company/${companyId}`).then((response) =>
                setCompany(response.data.company)
            );
        }
    }, [authToken, companyId]);

    return { company };
};
