import { UserDetailType } from '@/utils/apiTypes';
import { Dialog, Transition } from '@headlessui/react';
import {
    MenuAlt2Icon,
    ViewGridIcon,
    XIcon,
    CurrencyDollarIcon,
} from '@heroicons/react/outline';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { Header } from '../atoms/header.component';
import { BondaLogo } from '../misc/BondaLogo';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import GroupAdd from '@mui/icons-material/GroupAdd';

const navigationLinks = [
    {
        name: 'Activities',
        href: '/',
        icon: ViewGridIcon,
    },
    {
        name: 'Teams',
        href: '/teams',
        icon: GroupAdd,
    },
    {
        name: 'Company Culture Insights',
        href: '/insights',
        icon: AutoGraphIcon,
    },
    {
        name: 'Subscription',
        href: '/subscription',
        icon: CurrencyDollarIcon,
    },
    {
        name: 'Pricing',
        href: '/companyId/pricing',
        icon: RequestQuoteIcon,
    },
];

type SideMenuLayoutProps = {
    children: ReactNode;
    user: UserDetailType;
};

export const SideMenuLayout = ({ children, user }: SideMenuLayoutProps) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [navigation, setNavigation] = useState(navigationLinks);
    const router = useRouter();
    const companyId = user?.companyDetail?._id;

    useEffect(() => {
        if (companyId) {
            setNavigation(
                navigationLinks.map((link) => {
                    if (link.name == 'Pricing') {
                        navigationLinks[4].href = `/${companyId}/pricing`;
                    }
                    return link;
                })
            );
        }
    }, [companyId]);

    return (
        <>
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-40 md:hidden"
                        onClose={setSidebarOpen}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-grey-600 bg-opacity-75" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex z-40">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                                            <button
                                                type="button"
                                                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                onClick={() =>
                                                    setSidebarOpen(false)
                                                }
                                            >
                                                <span className="sr-only">
                                                    Close sidebar
                                                </span>
                                                <XIcon
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="flex-shrink-0 flex items-center px-4">
                                        <BondaLogo />
                                    </div>
                                    <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                        <nav className="px-2 space-y-1">
                                            {navigation.map((item) => (
                                                <Link
                                                    key={item.name}
                                                    href={item.href}
                                                    shallow
                                                >
                                                    <a
                                                        className={`${
                                                            item.href ==
                                                            router.pathname
                                                                ? 'bg-grey-100 text-gray-700'
                                                                : 'text-gray-600 hover:bg-grey-50 hover:text-gray-900'
                                                        }
                              group flex items-center px-2 py-2 text-base font-medium rounded-md
                            `}
                                                    >
                                                        <item.icon
                                                            className={`
                                ${
                                    item.href == router.pathname
                                        ? 'text-gray-900'
                                        : 'text-gray-400 group-hover:text-gray-500'
                                }
                                mr-4 flex-shrink-0 h-6 w-6
                              `}
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                    </a>
                                                </Link>
                                            ))}
                                        </nav>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                            <div
                                className="flex-shrink-0 w-14"
                                aria-hidden="true"
                            >
                                {/* Dummy element to force sidebar to shrink to fit close icon */}
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
                        <div className="flex items-center flex-shrink-0 px-4">
                            <BondaLogo className="w-[160px] ml-[-1.2rem]" />
                        </div>
                        <div className="mt-5 flex-grow flex flex-col">
                            <nav className="flex-1 px-2 pb-4 space-y-1">
                                {navigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        href={item.href}
                                        shallow
                                    >
                                        <a
                                            // key={item.name}
                                            // href={item.href}
                                            className={`${
                                                item.href == router.pathname
                                                    ? 'bg-grey-100 text-gray-900'
                                                    : 'text-gray-600 hover:bg-grey-50 hover:text-gray-900'
                                            }
                      group flex items-center px-2 py-2 text-sm font-medium rounded-md
                    `}
                                        >
                                            <item.icon
                                                className={`${
                                                    item.href == router.pathname
                                                        ? 'text-gray-700'
                                                        : 'text-gray-400 group-hover:text-gray-500'
                                                }
                        mr-3 flex-shrink-0 h-6 w-6
                      `}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </a>
                                    </Link>
                                ))}
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="md:pl-64 flex flex-col flex-1">
                    <div className="sticky top-0 flex-shrink-0 flex h-16 bg-white shadow">
                        <button
                            type="button"
                            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <MenuAlt2Icon
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </button>
                        <div className="flex-1 px-4 flex justify-end">
                            <Header user={user} />
                        </div>
                    </div>

                    <main className="flex-1">
                        <div className="py-6">
                            {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">{navigation.}</h1>
              </div> */}
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                <div className="py-4">{children}</div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
};
